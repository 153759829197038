'use client';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../../i18n-config';
import Link from 'next/link';
import SiteSocialNetworkComponent from '@/components/home/SiteSocialNetworkComponent';

export default function Custom404() {
  const params = useParams<{ lang?: string }>();

  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');
  return (
    <div className="tw-overflow-hidden">
      <div className="tw-flex tw-h-screen tw-w-screen tw-justify-center tw-items-center">
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-8">
          <div className="tw-text-center tw-text-[#3654af] tw-text-[80px] tw-font-extrabold">😞</div>
          <div className="tw-text-center tw-text-[#3654af] tw-text-[80px] tw-font-extrabold">{t('Error404/title')}</div>
          <div className="tw-text-center tw-text-[#11181c] tw-text-xl tw-font-extrabold">
            {t('Error404/description')}
          </div>
          <Link href="/" className="tw-px-5 tw-py-2.5 tw-bg-[#4167da] tw-rounded-[10px]">
            <div className="tw-text-[#fbfcfd] tw-text-sm tw-font-medium">{t('Error404/btn')}</div>
          </Link>
        </div>
      </div>
      <SiteSocialNetworkComponent />
    </div>
  );
}
